require("normalize.css/normalize.css");
require("./styles/index.scss");

import $ from "jquery";
import LazyLoad from "vanilla-lazyload";
import Swiper from "swiper";
import { Pagination, Autoplay, EffectCreative } from "swiper/modules";
import "swiper/swiper-bundle.css";

Swiper.use([Pagination, Autoplay, EffectCreative]);
var lazyLoadInstance = new LazyLoad();

$(window).on("load", function () {
  setTimeout(function () {
    $("body").addClass("loaded");
    if (swiper) swiper.autoplay.start();
  }, 1000);
});

setTimeout(function () {
  $("body").addClass("loaded");
  if (swiper) swiper.autoplay.start();
}, 30000);

const swiper = new Swiper(".intro-container", {
  loop: true,
  pagination: {
    el: ".intro-pagination",
    clickable: true,
  },
  autoplay: {
    delay: 5000,
    enabled: false,
  },
  grabCursor: true,
  effect: "creative",
  creativeEffect: {
    prev: {
      opacity: 0,
      translate: ["-120%", 0, -500],
    },
    next: {
      opacity: 0,
      translate: ["120%", 0, -500],
    },
  },
  on: {
    slideChange: function () {
      var introSection = document.querySelector(".intro");

      if (swiper && swiper.realIndex === 1) {
        introSection.classList.add("dark");
        introSection.classList.remove("light");
      } else {
        introSection.classList.add("light");
        introSection.classList.remove("dark");
      }
    },
  },
});

function calculateVh(initial) {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", vh + "px");
  if (initial == true) document.documentElement.style.setProperty("--ivh", vh + "px");
}

calculateVh(true);
//window.addEventListener("resize", calculateVh);
window.addEventListener("orientationchange", calculateVh(true));

$(document).on("click", 'a[href^="#"]:not([href="#"]), a[href^="/#"]:not([href="/#"])', function (event) {
  var newPath = $(this).attr("href");
  if (newPath.startsWith("/#") && window.location.pathname === "/") {
    event.preventDefault();
    var anchor = newPath.substring(2);
    var scrollTo = $("#" + anchor).offset().top;
    $("html, body").animate(
      {
        scrollTop: scrollTo,
      },
      500
    );
  } else if (newPath.startsWith("/#")) {
    window.location.href = window.location.origin + newPath;
  }
});

$(document).on("click", ".header-nav-handler", function (event) {
  event.preventDefault();
  $("body").toggleClass("nav-visible");
  $("html").css("overflow", $("body").hasClass("nav-visible") ? "hidden" : "");
});

$(document).on("click", ".header-nav a, .nav-fade", function (event) {
  $("body").removeClass("nav-visible");
  $("html").css("overflow", "");
});

const TON_CONTRACT =
  "0:0000000000000000000000000000000000000000000000000000000000000000";

// Fetch counters
$.ajax({
  url: "https://api.tonstakers.com/cache/v1/blockchain/staking",
  method: "GET",
  success: function (response) {
    var publicData = response.data;
    var protocolFee = 0.86;
    // Convert TVL from TON to USD
    var tonToUsdRate = publicData.rates[TON_CONTRACT];
    var tvlInUsd = (parseInt(publicData.staking_data.tvl) / 1e9) * tonToUsdRate; // Dividing by 1e9 for correct conversion

    // Display TVL in the proper format
    var formattedTvlInUsd =
      tvlInUsd > 1e6 ? (tvlInUsd / 1e6).toFixed(1) + " M" : tvlInUsd.toFixed(2);

    // Display stakers count with commas
    var formattedStakers = publicData.staking_data.stakers
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Calculate the average APY based on the filtered items
    var historicalApy = publicData.staking_data.historicalApy;

    // Display the values
    $("#counter-stakers").text(formattedStakers).removeClass("shimmer");
    $("#counter-apy")
      .text(historicalApy.toFixed(2) + "%")
      .removeClass("shimmer");
    $("#counter-tvl")
      .text("$" + formattedTvlInUsd)
      .removeClass("shimmer");
  },
  error: function (error) {
    console.log("Error fetching data:", error);
  },
});

$(document).ready(function () {
  var lastScrollTop = 0;
  var delta = 50;

  if ($(".header").length) {
    $(".header").clone().addClass("sticky hidden").appendTo("body");
  }

  function adjustHeader() {
    var st = $(this).scrollTop();
    if (Math.abs(lastScrollTop - st) <= delta) return;

    if (st > lastScrollTop || lastScrollTop == 0) {
      if ($(window).scrollTop() > window.innerHeight) {
        $(".header.sticky").removeClass("hidden");
      }
    } else {
      $(".header.sticky").addClass("hidden");
    }
    lastScrollTop = st <= 0 ? 0 : st;
  }

  $(window).scroll(adjustHeader);
});

$(".faq-list-item-question").on("click", function (event) {
  this.parentElement.classList.toggle("enrolled");
});
